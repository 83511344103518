import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Fade from 'react-reveal/Fade'

import TemplateWrapper from '../components/layout'

class Content extends Component {
  render() {
    return (
      <div className='r3__content__blog' dangerouslySetInnerHTML={{ __html: this.props.content }} />
    )
  }
}

class Video extends Component {
  render() {
    return (
      <Fade bottom distance='40px'>
        <div className='r3__content__inner' dangerouslySetInnerHTML={{ __html: this.props.video }} />
      </Fade>
    )
  }
}

class PostTemplate extends Component {

  state = {
    lightbox: {
      active: false,
      url: '',
    },
  }

  _renderBlock(param, el, i) {
    let block = {
      'WordPressAcf_content': (el, i) => (<Content {...el} key={i} />),
      'WordPressAcf_video': (el, i) => (<Video {...el} key={i} />),
      'WordPressAcf_image': (el, i) => (
        <Fade bottom distance='40px' key={i}>
          <picture className='r3__image__wrapper'>
            <div
              className='r3__image'
              style={{backgroundImage: `url(${el.image && el.image.source_url})`}}
              onClick={this.openLightbox(el.image && el.image.source_url)}
            />
            <p>{ el.caption }</p>
          </picture>
        </Fade>
      ),
    }[param]

    if (!block) return

    return block(el, i)
  }

  openLightbox = url => event => {
    let lightbox = {
      active: true,
      url,
    }
    this.setState({ lightbox })
  }

  closeLightbox = () => event => {
    let { lightbox } = this.state
    lightbox.active = false
    this.setState({ lightbox })
  }

  render() {

    let data = this.props.data.wordpressPost
    let { lightbox } = this.state

    return (
      <TemplateWrapper {...this.props}>
        <Helmet bodyAttributes={{ class: 'blog' }}>
          <title>{ data.yoast_meta.yoast_wpseo_title }</title>
          <meta name="description" content={ data.yoast_meta.yoast_wpseo_metadesc } />
          { data.tags && <meta name="keywords" content={ data.tags.map(tag => `${tag.name}, `) } /> }

          <meta property="og:title" content={ data.yoast_meta.yoast_wpseo_title } />
          <meta property="og:description" content={ data.yoast_meta.yoast_wpseo_metadesc } />
          { data.featured_media && <meta property="og:image" content={data.featured_media.source_url} /> }
        </Helmet>
        <div>
          <div className="r3__section r3--dark r3--no-head" style={{ backgroundImage: `url(${data.featured_media && data.featured_media.source_url})` }}>
            <div className="r3__content">
              <Fade bottom distance='40px'>
                { data.title &&
                  <h1 dangerouslySetInnerHTML={{ __html: data.title }} />
                }
              </Fade>
            </div>
          </div>

          <div className="r3__section">
            <div className="r3__content">
              { data.acf.blocks_post && data.acf.blocks_post.map((el, i) => {
                return this._renderBlock(el.__typename, el, i)
              }) }
            </div>
          </div>

          <div className={`r3__lightbox ${lightbox.active && 'r3__lightbox--active'}`} onClick={this.closeLightbox()}>
            <img src={lightbox.url} />
          </div>

        </div>
      </TemplateWrapper>
    )
  }

}

export default PostTemplate

export const pageQuery = graphql`
  query currentPostQuery($id: String!) {
    wordpressPost(id: { eq: $id }) {
      wordpress_id
      slug
      title
      date
      content
      tags {
        name
        slug
      }
      featured_media {
        source_url
      }
      acf {
        blocks_post {
          __typename
          ... on WordPressAcf_content {
            content
          }
          ... on WordPressAcf_image {
            image {
              source_url
              media_details {
                width
                height
              }
            }
            caption
          }
          ... on WordPressAcf_video {
            video
          }
        }
      }
      categories {
        slug
        name
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
  }
`
